import { Section } from 'shared/components'
import styled from 'styled-components'

export const SectionContainer = styled(Section)`
  margin: ${(p) => p.theme.spacing(3, 0, 2)};

  ${(p) => p.theme.breakpoints.up('md')} {
    margin: ${(p) => p.theme.spacing(3, 0, 10)};
  }
`

export const ContentContainer = styled.div`
  text-align: justify;

  ${(p) => p.theme.breakpoints.down('md')} {
    padding: ${(p) => p.theme.spacing(3, 3, 5)};
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(0, 3, 5)};
  }

  a {
    color: ${(p) => p.theme.palette.text.muted};
  }
`

export const ProjectHeader = styled.div`
  padding-top: ${(p) => p.theme.spacing(1)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const ExternalLink = styled.a`
  &:hover {
    & > * {
      color: ${(p) => p.theme.palette.text.primary};
    }
  }

  & > * {
    color: ${(p) => p.theme.palette.text.light};
    transition: ${(p) => p.theme.transition.create('color')};
    text-decoration: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 5px;
    }
  }
`

export const Insights = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: ${(p) => p.theme.spacing(1)};
  }
`
