import config, { paths } from 'config'
import { formatDistanceToNow } from 'date-fns'
import { pl } from 'date-fns/locale'
import { Interweave } from 'interweave'
import { HashtagMatcher, MentionMatcher } from 'interweave-autolink'
import { EmojiMatcher } from 'interweave-emoji'
import { useEffect } from 'react'
import { Heart, MessageCircle } from 'react-feather'
import { scroller } from 'react-scroll'
import { Hashtag, Layout, SEO, Typography } from 'shared/components'
import { useEmojis } from 'shared/utils'
import { Footer, Header } from '../../sections'
import Gallery, { GalleryItem } from './Gallery'
import {
  ContentContainer,
  ExternalLink,
  Insights,
  ProjectHeader,
  SectionContainer,
} from './ProjectDetails.style'

interface ProjectDetailsProps {
  pageContext: {
    id: string
    caption: string
    permalink: string
    likes: number
    comments: number
    timestamp: number
    preview: string
    carouselImages: GalleryItem[]
  }[]
}

const ProjectDetails = ({ pageContext }: ProjectDetailsProps) => {
  const emojiProps = useEmojis()
  const posts = Object.values(pageContext)
  const [{ caption, preview }] = posts
  const [projectId = ''] = caption.match(config.projectIdRegex) || []
  const [_, id, title = projectId] = projectId.trim().match(/^(\d+) (.+)/) || []

  useEffect(() => {
    const postId = new URLSearchParams(location.search).get('postId')
    if (!postId) return

    scroller.scrollTo(postId, {
      offset: -100,
      duration: 1000,
      smooth: 'easeInOutQuint',
    })
  }, [])

  return (
    <Layout
      topBar={<Header />}
      footer={<Footer />}
      seo={
        <SEO
          title={title}
          image={preview}
          description="Zobacz projekt MAVI Pracownia Projektowa. Zapraszam do współpracy."
        />
      }
    >
      <SectionContainer>
        <Typography variant="h3" color="light">
          &#x23;{id}
        </Typography>
        <Typography variant="h1">{title}</Typography>
        <br />
        {posts.map((post) => {
          const description = post.caption.replace(projectId, '').trim()
          const galleryItems = (
            post.carouselImages.length > 0 ? post.carouselImages : [post]
          ).map((item, index) => ({
            ...item,
            alt: `Wizualizacja projektu "${title}"`,
          }))
          return (
            <ContentContainer key={post.id} id={post.id}>
              <Gallery items={galleryItems} />
              <ProjectHeader>
                <ExternalLink
                  href={post.permalink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Typography variant="caption" color="inherit" align="left">
                    {formatDistanceToNow(new Date(post.timestamp * 1000), {
                      locale: pl,
                      addSuffix: true,
                    })}
                  </Typography>
                </ExternalLink>
                <Insights>
                  <ExternalLink
                    href={post.permalink}
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Komentarze: ${post.comments || 0}`}
                  >
                    <Typography variant="caption" color="inherit" align="right">
                      <MessageCircle />
                      <span>{post.comments || 0}</span>
                    </Typography>
                  </ExternalLink>
                  <ExternalLink
                    href={post.permalink}
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Polubienia: ${post.likes || 0}`}
                  >
                    <Typography variant="caption" color="inherit" align="right">
                      <Heart />
                      <span>{post.likes || 0}</span>
                    </Typography>
                  </ExternalLink>
                </Insights>
              </ProjectHeader>
              <Typography variant="body2" align="justify">
                <Interweave
                  {...emojiProps}
                  content={description}
                  hashtagUrl={`${paths.projects}?q={{hashtag}}`}
                  mentionUrl="https://www.instagram.com/{{mention}}"
                  newWindow
                  matchers={[
                    new MentionMatcher('mention'),
                    new HashtagMatcher('hashtag', {}, Hashtag),
                    new EmojiMatcher('emoji', {
                      convertEmoticon: false,
                      convertShortcode: true,
                      convertUnicode: true,
                    }),
                  ]}
                />
              </Typography>
            </ContentContainer>
          )
        })}
      </SectionContainer>
    </Layout>
  )
}

export default ProjectDetails
