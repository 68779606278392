import { useMemo } from 'react'
import ReactImageGallery, { ReactImageGalleryProps } from 'react-image-gallery'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import { GalleryContainer } from './Gallery.style'
import {
  renderFullscreenButton,
  renderLeftNav,
  renderRightNav,
} from './Gallery.utils'

export interface GalleryItem {
  id: string
  preview: string
  alt: string
}

export interface GalleryProps {
  items: GalleryItem[]
  galleryProps?: ReactImageGalleryProps
}

const Gallery = ({ items, ...props }: GalleryProps) => {
  const galleryItems = useMemo(
    (): ReactImageGalleryProps['items'] =>
      items.map(({ preview, alt }) => ({
        original: preview,
        originalAlt: alt,
        thumbnail: preview,
        thumbnailAlt: alt,
      })),
    []
  )
  return (
    <GalleryContainer {...props}>
      <ReactImageGallery
        showThumbnails={false}
        showPlayButton={false}
        showBullets={items.length > 1}
        items={galleryItems}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        renderFullscreenButton={renderFullscreenButton}
      />
    </GalleryContainer>
  )
}
export default Gallery
