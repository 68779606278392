import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { ChevronLeft, ChevronRight, Maximize } from 'react-feather'
import { ReactImageGalleryProps } from 'react-image-gallery'
import { FullScreenButton, LeftNav, RightNav } from './Gallery.style'

export const renderLeftNav: ReactImageGalleryProps['renderLeftNav'] = (
  onClick
) => (
  <LeftNav onClick={onClick} aria-label="Poprzedni">
    <ChevronLeft />
  </LeftNav>
)

export const renderRightNav: ReactImageGalleryProps['renderRightNav'] = (
  onClick
) => (
  <RightNav onClick={onClick} aria-label="Następny">
    <ChevronRight />
  </RightNav>
)

export const renderFullscreenButton: ReactImageGalleryProps['renderFullscreenButton'] =
  (onClick) => (
    <FullScreenButton onClick={onClick} aria-label="Pełen ekran">
      <Maximize />
    </FullScreenButton>
  )

export const createRenderItem =
  (image: IGatsbyImageData): ReactImageGalleryProps['renderItem'] =>
  (item) =>
    <GatsbyImage alt={item.originalAlt || ''} image={image} />
