import styled, { css } from 'styled-components'

export const GalleryContainer = styled.div`
  .image-gallery-content {
    &.fullscreen {
      .image-gallery-slide .image-gallery-image {
        max-height: 100vh;
        height: 100%;
      }
      .image-gallery-slides {
        max-height: 100vh;
        height: 100%;
        background-color: ${(p) => p.theme.palette.common.black};
      }
    }
    .image-gallery-slide .image-gallery-image {
      max-height: 500px;
      height: 500px;
    }

    .image-gallery-slides {
      max-height: 500px;
      height: 500px;
      background-color: ${(p) => p.theme.palette.background.light};
    }
  }

  .image-gallery-thumbnail {
    border-width: 2px;
    cursor: pointer;
    &.active {
      border: 2px solid ${(p) => p.theme.palette.text.muted};
    }
    &:hover {
      border: 2px solid ${(p) => p.theme.palette.text.muted};
    }
  }

  .image-gallery-bullets .image-gallery-bullet {
    box-shadow: 0 4px 10px rgb(0 0 0 / 0.5);
    &.active {
      background: ${(p) => p.theme.palette.background.light};
      &:hover {
        border-color: ${(p) => p.theme.palette.background.primary};
        background: ${(p) => p.theme.palette.background.primary};
      }
    }
    &:hover {
      border-color: ${(p) => p.theme.palette.background.primary};
      background: ${(p) => p.theme.palette.background.primary};
    }
  }
`

const GalleryButton = css`
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  z-index: 4;
  cursor: pointer;

  svg {
    transition: ${(p) =>
      p.theme.transition.create([
        'color',
        'transform',
        'filter',
        'width',
        'height',
      ])};
    color: ${(p) => p.theme.palette.background.light};
    filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.5));
    width: 60px;
    height: 60px;

    ${(p) => p.theme.breakpoints.down('md')} {
      width: 30px;
      height: 30px;
    }
  }

  &:hover {
    svg {
      color: ${(p) => p.theme.palette.background.primary};
      transform: scale(1.1);
      filter: drop-shadow(0 4px 4px rgb(0 0 0 / 0.45));
    }
  }
`

export const LeftNav = styled.button`
  ${GalleryButton};
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  padding: 50px 10px;
`

export const RightNav = styled.button`
  ${GalleryButton};
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  padding: 50px 10px;
`

export const FullScreenButton = styled.button`
  ${GalleryButton};
  right: 0;
  bottom: 0;
  padding: 25px;

  svg {
    width: 30px;
    height: 30px;
  }

  ${(p) => p.theme.breakpoints.down('md')} {
    padding: 15px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`
